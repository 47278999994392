import React, { useState } from "react";

const Gallery = ({ images }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const currentImage = images[currentImageIndex];

  return (
    <div className="gallery grid grid-cols-4 gap-4 bg-white max-sm:grid-cols-2">
      {images.map((image, index) => (
        <div
          key={index}
          className={`gallery-item ${
            index === 0 || index === 5 ? "col-span-2" : ""
          }`}
          data-aos-delay={index == 0 ? "100" : `${100 + (index + 1) * 100}`}
          data-aos="fade-up"
          data-aos-easing="linear"
        >
          <img
            src={image.url}
            alt="Gallery Image"
            className="cursor-pointer object-cover h-full"
            onClick={() => openModal(index)}
          />
        </div>
      ))}

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div
            className="modal-overlay bg-black opacity-50 fixed top-0 left-0 w-full h-full"
            onClick={closeModal}
          ></div>
          <div className="modal-content bg-white p-4 rounded-lg shadow-lg">
            <span
              className="close-btn absolute top-2 right-2 text-gray-700 cursor-pointer"
              onClick={closeModal}
            >
              &times;
            </span>
            <img src={currentImage.url} alt="Gallery Image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
